<template lang="pug">
transition(name="fademodal" appear)
div#builder(v-if="user.roleId===4")
		div.visual(:class="'size-'+formValues.vinylFormat")
			//-div#testbed
				label
					span Sale Price £:
					input#pressing(type="number" v-model="formValues.salePrice")
				label
					span Pressing:
					input#pressing(type="number" v-model="formValues.pressing")
				label
					span Test Stock Qty:
					select(v-model="formValues.testStockQty")
						option(v-for="val in formOptions.testStockQty" :value="val") {{ val }}
				label
					span User Stock Qty:
					select(v-model="formValues.userStockQty")
						option(v-for="val in formOptions.userStockQty" :value="val") {{ val }}
			ul#debug
				li 
					pre costs: {{dbCosts}}
				li 
					pre vals: {{formValues}}
					pre calcs: {{calcs}}
			hgroup
				h3 {{formValues.labelName||'&nbsp;'}}
				h1 {{formValues.albumTitle||'&nbsp;'}}
				h2 {{formValues.artistName||'&nbsp;'}}
			div
				//-div.artwork(:class="{flipped:flipArtwork===true,reflipped:flipArtwork===false,inner:revealInner}")
				div.artwork(:class="artworkClasses")
					div
						div.album.front(:class="albumClasses")
							div.record(:class="{reveal:revealRecord}" :style="recordStyle")
								img(src="/img/vinyl.png" alt="vinyl" :style="imgStyle")
								div.label(:class="formValues.labelType")
									img(v-if="formValues.artwork.label1A" :src="formValues.artwork.label1A" alt="Label front")
							svg.inner
								mask(id="front-inner-mask")
									g(stroke="none" stroke-width="1" fill="none" fill-rule="evenodd")
										g(transform="translate(-264.000000, -283.000000)" fill="white")
											path(d="M264,283 L664,283 L664,683 L264,683 L264,283 Z M464,559 C505.973641,559 540,524.973641 540,483 C540,441.026359 505.973641,407 464,407 C422.026359,407 388,441.026359 388,483 C388,524.973641 422.026359,559 464,559 Z")
								g(mask="url(#front-inner-mask)")
									rect(width="§00" height="100")
									image(v-if="formValues.artwork.inner1A" :href="formValues.artwork.inner1A" x="0" y="0" height="100%" width="100%" alt="Front sleeve artwork")
							picture(:class="formValues.jacketType")
								img(v-if="formValues.artwork.cover" :src="formValues.artwork.cover" alt="Front cover artwork")
						div.album.back(:class="albumClasses")
							div.record(:class="{reveal:revealRecord}" :style="recordStyle")
								img(src="/img/vinyl.png" alt="vinyl" :style="imgStyle")
								div.label(:class="formValues.labelType")
									img(v-if="formValues.artwork.label1B" :src="formValues.artwork.label1B" alt="Label back")
							svg.inner
								mask(id="back-inner-mask")
									g(stroke="none" stroke-width="1" fill="none" fill-rule="evenodd")
										g(transform="translate(-264.000000, -283.000000)" fill="white")
											path(d="M264,283 L664,283 L664,683 L264,683 L264,283 Z M464,559 C505.973641,559 540,524.973641 540,483 C540,441.026359 505.973641,407 464,407 C422.026359,407 388,441.026359 388,483 C388,524.973641 422.026359,559 464,559 Z")
								g(mask="url(#back-inner-mask)")
									rect(width="§00" height="100")
									image(v-if="formValues.artwork.inner1B" :href="formValues.artwork.inner1B" x="0" y="0" height="100%" width="100%" alt="Back sleeve artwork")
							picture(:class="formValues.jacketType")
								img(v-if="formValues.artwork.back" :src="formValues.artwork.back" alt="Back cover artwork")
				div.records
					//-div.record(:class="{on:formValues.vinylCount>3}" :style="recordStyle")
						img(src="/img/vinyl.png" alt="vinyl" :style="imgStyle")
						div.label(:class="formValues.labelType")
							img(v-if="formValues.artwork.label4A" :src="formValues.artwork.label4A" alt="Label front")
					//-div.record(:class="{on:formValues.vinylCount>2}" :style="recordStyle")
						img(src="/img/vinyl.png" alt="vinyl" :style="imgStyle")
						div.label(:class="formValues.labelType")
							img(v-if="formValues.artwork.label3A" :src="formValues.artwork.label3A" alt="Label front")
					div.record(:class="{on:formValues.vinylCount>1}" :style="recordStyle")
						img(src="/img/vinyl.png" alt="vinyl" :style="imgStyle")
						div.label(:class="formValues.labelType")
							img(v-if="formValues.artwork.label2A" :src="formValues.artwork.label2A" alt="Label front")
				button.icon.pulse.reveal(@click="showRecord")
					span Reveal Vinyl
				button.icon.pulse.flip(@click="flip")
					span Flip
				button.icon.pulse.inner.front(@click="showInner")
					span Reveal Inner
				button.icon.pulse.inner.back(@click="showInner")
					span Reveal Inner
		div.options
			ol.steps
				li(:class="{done:step>=1,active:step==1}") Detail
				li(:class="{done:step>=2,active:step==2}") Spec
				li(:class="{done:step>=3,active:step==3}") Volume
				li(:class="{done:step>=4,active:step==4}") Artwork
				li(:class="{done:step>=5,active:step==5}") Audio
			div
				Form(:validation-schema="currentSchema" v-slot="{ handleSubmit, meta }" @submit="nextStep")
					template(v-if="step===1")
						//-pre meta: {{meta}}
						label
							Field(name="albumTitle" type="text" placeholder="Required" autocomplete="off" v-model="formValues.albumTitle")
							span Album Title
							transition(name="fade")
								ErrorMessage(name="albumTitle" as="em")
						label
							Field(name="artistName" type="text" placeholder="Required" autocomplete="off" v-model="formValues.artistName")
							span Artist Name
							transition(name="fade")
								ErrorMessage(name="artistName" as="em")
							label
								Field(name="labelName" type="text" placeholder="Optional" autocomplete="off" v-model="formValues.labelName")
								span Label Name
								transition(name="fade")
									ErrorMessage(name="labelName" as="em")
					template(v-if="step===2")
						fieldset.range(:data-stops="formOptions.vinylSpeed.length")
							legend What speed are you spinning?
							template(v-for="(vinylSpeed, index) in formOptions.vinylSpeed" :index="index")
								label(:class="{checked:formValues.vinylSpeed===vinylSpeed.value}")
									Field(name="vinylSpeed" type="radio" v-model="formValues.vinylSpeed" :value="vinylSpeed.value")
									span {{vinylSpeed.option}}
							div.vinyl-speed
								big {{formValues.vinylSpeed}}
								small rpm
						fieldset.range(:data-stops="formOptions.vinylFormat.length")
							legend What format are you pressing?
							template(v-for="(vinylFormat, index) in formOptions.vinylFormat" :index="index")
								label(:class="{checked:formValues.vinylFormat===vinylFormat.value}")
									Field(name="vinylFormat" type="radio" v-model="formValues.vinylFormat" :value="vinylFormat.value")
									span {{vinylFormat.option}}
							div.vinyl-format
								big {{formValues.vinylFormat}}"
								small {{minsPerSide}} mins <br>per side*
						fieldset.range(:data-stops="formOptions.vinylCount.length")
							legend How many vinyl in your album?
							template(v-for="(vinylCount, index) in formOptions.vinylCount" :index="index")
								label(:class="{checked:formValues.vinylCount===vinylCount.value}")
									Field(name="vinylCount" type="radio" v-model="formValues.vinylCount" :value="vinylCount.value")
									span {{vinylCount.option}}
							div.vinyl-count
								img(src="/img/vinyl.png" alt="vinyl")
								span {{formValues.vinylCount}}
						fieldset.range(:data-stops="formOptions.vinylGrade.length")
							legend How heavy do you want your vinyl?
							template(v-for="(vinylGrade, index) in formOptions.vinylGrade" :index="index")
								label(:class="{checked:formValues.vinylGrade===vinylGrade.value}")
									Field(name="vinylGrade" type="radio" v-model="formValues.vinylGrade" :value="vinylGrade.value")
									span {{vinylGrade.option}}
							div.vinyl-grade
								span {{formValues.vinylGrade}}g
						fieldset.range(:data-stops="formOptions.innerType.length")
							legend Which inner would you like?
							template(v-for="(innerType, index) in formOptions.innerType" :index="index")
								label(:class="{checked:formValues.innerType===innerType.value}")
									Field(name="innerType" type="radio" v-model="formValues.innerType" :value="innerType.value" @click="innerChange(innerType)")
									span.wrap {{innerType.option}}
							div.inner(:class="formValues.innerType,formValues.innerHole")
								img(v-if="formValues.artwork.inner1A" :src="formValues.artwork.inner1A" alt="Front sleeve artwork")
						fieldset.range(:data-stops="formOptions.innerHole.length")
							legend Do you want holes in your inner?
							template(v-for="(innerHole, index) in formOptions.innerHole" :index="index")
								label(:class="{checked:formValues.innerHole===innerHole.value, disabled:disableHole(innerHole)}")
									Field(name="innerHole" type="radio" v-model="formValues.innerHole" :value="innerHole.value" :disabled="disableHole(innerHole)")
									span {{innerHole.option}}
							div.hole
						fieldset.range(:data-stops="formOptions.jacketType.length")
							legend Would you like an outer sleeve?
							template(v-for="(jacketType, index) in formOptions.jacketType" :index="index")
								label(:class="{checked:formValues.jacketType===jacketType.value, disabled:disableJacket(jacketType)}")
									Field(name="jacketType" type="radio" v-model="formValues.jacketType" :value="jacketType.value" :disabled="disableJacket(jacketType)")
									span {{jacketType.option}}
							div.jacket(:class="formValues.jacketType")
								img(v-if="formValues.artwork.cover" :src="formValues.artwork.cover" alt="Front cover artwork")
						fieldset.range(:data-stops="formOptions.labelType.length")
							legend Which labels would you like?
							template(v-for="(labelType, index) in formOptions.labelType" :index="index")
								label(:class="{checked:formValues.labelType===labelType.value}")
									Field(name="labelType" type="radio" v-model="formValues.labelType" :value="labelType.value")
									span {{labelType.option}}
							//-div.jacket(:class="formValues.labelType.option")
							div.vinyl-colour(:class="formValues.labelType" style="background-color:#323232;")
								img(src="/img/vinyl.png" alt="vinyl" style="mix-blend-mode: plus-lighter;")
								img(v-if="formValues.artwork.label1A" :src="formValues.artwork.label1A" alt="label artwork")
								span
								//-small {{recordColour}}
						fieldset.range(:data-stops="formOptions.shrink.length")
							legend Shall we shrink wrap your album?
							template(v-for="(shrink, index) in formOptions.shrink" :index="index")
								label(:class="{checked:formValues.shrink===shrink.value}")
									Field(name="shrink" type="radio" v-model="formValues.shrink" :value="shrink.value")
									span {{shrink.option}}
						fieldset.colour(:data-stops="formOptions.vinylColour.length")
							legend What colour vinyl would you like?
							template(v-for="(vinylColour, index) in formOptions.vinylColour" :index="index")
								label(:class="{checked:formValues.vinylColour===vinylColour.value}")
									Field(name="vinylColour" type="radio" v-model="formValues.vinylColour" :value="vinylColour.value" :style="{background:vinylColour.colour}")
									span {{vinylColour.option}}
							div.vinyl-colour(:style="recordStyle")
								img(src="/img/vinyl.png" alt="vinyl" :style="imgStyle")
								span
								small {{recordColour}}
					template(v-if="step===3")
						label
							Field(name="pressing" type="text" placeholder="Required" autocomplete="off" v-model="formValues.pressing")
							span Order Quantity
							transition(name="fade")
								ErrorMessage(name="pressing" as="em")
						fieldset.range.field(:data-stops="formOptions.userStockQty.length")
							legend User Stock Quantity
							template(v-for="(userStockQty, index) in formOptions.userStockQty" :index="index")
								label(:class="{checked:formValues.userStockQty===userStockQty}")
									Field(name="userStockQty" type="radio" v-model="formValues.userStockQty" :value="userStockQty")
									span(v-if="userStockQty") {{userStockQty}}
									span(v-else) None
						label
							Field(name="testStockQty" type="checkbox" :value="5" :uncheckedValue="0" v-model="formValues.testStockQty")
							span Order 5 album test pressing?
						label.price(:class="{symbol:formValues.salePrice}")
							Field(name="salePrice" type="number" placeholder="Required" autocomplete="off" v-model="formValues.salePrice")
							span Album Sale Price (exc. VAT)
							transition(name="fade")
								ErrorMessage(name="salePrice" as="em")
						//-fieldset.range(:data-stops="formOptions.testStockQty.length")
							legend Test Stock Quantity
							template(v-for="(testStockQty, index) in formOptions.testStockQty" :index="index")
								label(:class="{checked:formValues.testStockQty===testStockQty}")
									Field(name="testStockQty" type="radio" v-model="formValues.testStockQty" :value="testStockQty")
									span(v-if="testStockQty") {{testStockQty}}
									span(v-else) None
						
						h3 Projections
						p Production Threshold: {{calcProductionThreshold}} Sales
						p Production Cost: £ {{calcSubtotal}}
						p Sales Stock Value: £ {{calcSalesStockValue}}
						p User Stock Value: £ {{calcUserStockValue}}
						p TRR Project Fee: £ {{calcTrrProjectFee}}
						p User Profit: £ {{calcUserProfit}}
					template(v-if="step===4")
						p Step 3
					template(v-if="step===5")
						p Step 3
					ul.ctrl
						li.back
							button.text.outline(type="button" @click="prevStep") Back			
						li.but-price
							//Price(:price="projectTotal" label="Total" vat="exc")
							Price(:price="calcTotal" label="Total" vat="exc")
							button.text(v-if="step!==5" type="submit" :disabled="!meta.valid") Next
							button.text(v-else type="submit" :disabled="!meta.valid") Finish
</template>

<script>
/*
	VAT NEEDS TO BE APPLIED FOR ALL OVERSEAS ORDERS.
*/		
	
//import AccountService from '../services/AccountService';
import { Form, Field, ErrorMessage } from 'vee-validate';
import * as Yup from 'yup';
import Price from '../components/Price';
	
export default {
	name: 'AccountProject',
	components: {
		Form,
		Field,
		ErrorMessage,
		Price,
	},	
	data() {
		/*const schemaStep1 = Yup.object().shape({
			albumTitle: Yup.string().label('Album Title').required(),
			artistName: Yup.string().label('Artist Name').required(),			
			labelName: Yup.string().label('Label Name'),			
		});*/
		const schemas = [
			Yup.object().shape({
				albumTitle: Yup.string().label('Album Title').required(),
				artistName: Yup.string().label('Artist Name').required(),			
				labelName: Yup.string().label('Label Name'),			
			}),
		];
		
		return {
//			projectId: parseInt(this.$route.params.id),
			projectId: this.$route.params.id,
			step: 2,
			formOptions: {
				vinylCount: [
					{option: 1, value: 1},
					{option: 2, value: 2},
//					{option: 3, value: 3},
//					{option: 4, value: 4},
				],
				vinylGrade: [
					{option: '140g', value: 140},
					{option: '180g', value: 180},
				],
				vinylFormat: [
					{option: '7"', value: 7},
					{option: '10"', value: 10},
					{option: '12"', value: 12},
				],
				vinylSpeed: [
					{option: '33 rpm', value: 33},
					{option: '45 rpm', value: 45},
				],
				innerType: [
					{option: 'White Paper', value: 'bag white'},
					{option: 'Black Paper', value: 'bag black'},
					{option: 'White Card', value: 'card white'},
					{option: 'Black Card', value: 'card black'},
					{option: 'Printed Card', value: 'card printed'},
				],
				innerHole: [
//					{option: 'With holes', value: true},
//					{option: 'Without holes', value: false},
					{option: 'None', value: null},
					{option: 'Front', value: 'front'},
					{option: 'Back', value: 'back'},
					{option: 'Both', value: 'both'},
				],
				jacketType: [
					{option: 'None', value: null},
					//{option: 'White', value: 'white'},
					//{option: 'Black', value: 'black'},
					{option: 'Printed', value: 'printed'},					
//					{option: 'Gatefold', value: 'gatefold'},					
				],
				labelType: [
					{option: 'White', value: 'white'},
					{option: 'Printed', value: 'printed'},					
				],
				vinylColour: [
					// standard colours
					{option: 'Black', value: 'black', type: 'standard', style: 'background-color', colour: '#323232', blend: 'plus-lighter'},
					{option: 'Red Solid', value: 'No9STDTM', type: 'standard', style: 'background-color', colour: '#C71415', blend: 'plus-lighter' },
					{option: 'Orange Transparent', value: 'No3STDTM', type: 'standard', style: 'background', colour: '#F05E0A', blend: 'plus-lighter'},
					{option: 'Blue Solid', value: 'No12STDTM', type: 'standard', style: 'background-color', colour: '#023DB7', blend: 'plus-lighter'},
					{option: 'Yellow Solid', value: 'No10STDTM', type: 'standard', style: 'background', colour: '#EACB16', blend: 'plus-lighter'},
					{option: 'Green Solid', value: 'No11STDTM', type: 'standard', style: 'background-color', colour: '#036844', blend: 'plus-lighter'},
					// limited edition colours
//					{option: 'Pink Opaque', value: 'No17SPCTM', type: 'limited', style: 'background-color', colour: '#036844', blend: 'plus-lighter'},
					// splatter colours
//					{option: 'No7', value: 'No7SPEECLAT', type: 'splatter', style: 'background-color', colour: '#036844', blend: 'plus-lighter'},
					// marble colours
//					{option: 'No1', value: 'No1STDMARBRE', type: 'marble', style: 'background-color', colour: '#036844', blend: 'plus-lighter'},
				],
				shrink: [
					{option: 'Unwrapped', value: false},
					{option: 'Wrapped', value: true},
				],
				testStockQty: [0,5],
				userStockQty: [0,5,10,15,20],
			},
			formValues: {
				vinylSpeed: 33,
				vinylFormat: 12,
				vinylCount: 1,
				vinylGrade: 140,
				jacketType: null,
				labelType: 'white',
				innerType: 'card white',
				innerHole: 'both',
				vinylColour: 'black',
				artwork: {
					// vinyl 1
					label1A: '/album/id2/six-from-seven-label.jpg',
					label1B: '/album/id2/six-from-seven-label.jpg',
					// vinyl 2
					label2A: '/album/id2/six-from-seven-label.jpg',
					label2B: '/album/id2/six-from-seven-label.jpg',
					// jacket
					cover: '/album/id2/six-from-seven-front.jpg', // non-gatefold [], gateford [][] (double width artwork)
					back: '/album/id2/six-from-seven-back.jpg', // non-gatefold [], gateford [][] (double width artwork)
					inner1A: '/album/id2/six-from-seven-front.jpg', // gatefold/non-gatefold printed inner A side (vinyl 1)
					inner1B: '/album/id2/six-from-seven-back.jpg', // gatefold/non-gatefold printed inner B side (vinyl 1)
					//gatefold
					inner2A: null, // gatefold printed inner A side (vinyl 2)
					inner2B: null, // gatefold printed inner A side (vinyl 2)
				},
				pressing: 300,
				shrink: false,
				testStockQty: 0,
				userStockQty: 10,
				salePrice: 29.00,
			},
			schemas,
			flipArtwork: null, // null is inportant
			flippingArtwork: null, // null is inportant,
			revealRecord: false,
			revealInner: false,
//			schemaStep1,
			
			// retrieve from database
			dbCosts: {
				trrFeePct: 20, // percentage
				trrFeeMin: 1000.00, // minimum change
				userStockPostage: 10.00, // per album cost (delivery of stock to artist)
				cutting: 280.00, // per vinyl cost
				processing: 266.40, // per vinyl cost
				packing: 0.18, // per vinyl (putting vinyl into sleave/bag) (CHANGED)
				vinyl140: { // per vinyl cost
					300: 1.66,
					500: 1.27,
					1000: 1.17,
					2000: 1.17,
				},
				vinyl160: 0.41, // per vinyl surcharge
				vinyl180: 0.41, // per vinyl surcharge
				colour: {
					cleaning: 100.00, // fixed surcharge for cleaning the machine
					standard: 0.95, // per vinyl cost (standard colour)
					limited: 1.60, // per vinyl cost (limited edition colour)
					splatter: 0.45, // per vinyl surcharge (optional for standard or limited)
					marble: 0.30, // per vinyl surcharge (optional for standard or limited)
				},
				label: { // four colour printed labels
					white: {
						minQty: 0, // minimum qty charged
						1: 0.10, // per vinyl cost						
						origination: 0.00, // fixed surcharge
					},
					printed: {
						minQty: 0, // minimum qty charged
						1: 0.34, // per vinyl cost
						origination: 12.00, // fixed surcharge (print ready artwork)
					},
				},
				packaging: {
					bag: { // per vinyl cost (disco bag with holes both sides)
						white: 0.22,
						black: 0.22,
					},
					card: { // per vinyl cost
						white: 0.40, // (spinless card sleeve with holes both sides)
						black: 0.40, // (spinless card sleeve with holes both sides)
						printed: { // (spinless card sleeve with optional holes front, back or both sides)
							300: 1.90,
							500: 1.10,
							1000: 0.70,							
						},
						hole: 0.10, // cost for holes (printed only), same cost for front, back or both
					},
					jacket3mm: { // per album cost (applicable when vinyl qty is 1)
						300: 1.90,
						500: 1.10,
						1000: 0.70,
					},
					jacket5mm: { // per album cost (applicable when vinyl qty is 2)
						300: 2.40,
						500: 1.60,
						1000: 0.95,
					},
					jacketGatefold: { // per album cost (applicable when vinyl qty is 2)
						300: 4.50,
						500: 2.90,
						1000: 1.65,
					},
					shrink: 0.40, // per album cost
				},
				delivery: 175.00, // per album (delivery from EU to UK)
				testPressing: {
					production: 60.00,
					delivery: 37.00,
				},
			},			
		}
	},
	async created() {
		try {			
//			const {data} = await AccountService.getProject();
//			this.projects = data;
			
		} catch(err) {
			console.log('err:',err);			
		}
	},
	computed: {
		calcs() {
			return {
				minPressing: this.minPressing,
				calcVinylCost: this.currencyFormat(this.calcVinylCost),
				calcPackingCost: this.currencyFormat(this.calcPackingCost),
				calcLabelCost: this.currencyFormat(this.calcLabelCost),
				calcInnerCost: this.currencyFormat(this.calcInnerCost),
				calcJacketCost: this.currencyFormat(this.calcJacketCost),
				calcShrinkCost: this.currencyFormat(this.calcShrinkCost),
				calcTestStockCost: this.currencyFormat(this.calcTestStockCost),
				calcSubtotal: this.currencyFormat(this.calcSubtotal),
				
				calcUserStockValue: this.currencyFormat(this.calcUserStockValue),
				calcTrrProjectFee: this.currencyFormat(this.calcTrrProjectFee),
				calcUserProfit: this.currencyFormat(this.calcUserProfit),				
//				calcRevenue: this.currencyFormat(this.calcRevenue),
				calcTotal: this.currencyFormat(this.calcTotal),
				calcProductionThreshold: this.calcProductionThreshold + ' units',
//pre calcRevenueThreshold: {{calcRevenueThreshold}}				
			}
		},
		artworkClasses() {
			let classes = [];
			
			if (this.flippingArtwork === true) {
				classes.push('flip-to-back');
			} else if (this.flippingArtwork === false) {
				classes.push('flip-to-front');
			}
			
			if (this.flipArtwork === true) {
				classes.push('flipped');
				
			} else if (this.flipArtwork === false) {
				classes.push('reflipped');
			}
			
			if (this.revealInner) {
				classes.push('inner');
			}
			
			if (this.formValues.jacketType) {
				classes.push('jacket');
			}
			
			return classes.join(' ');			
		},
		albumClasses() {
			let classes = [
				this.formValues.innerType,
			];
			
			if (this.formValues.innerHole) {
				classes.push('hole-' + this.formValues.innerHole);	
			}
			
			return classes.join(' ');			
		},
		minPressing() {
			// ensure pressing is equal to minimum order
			const vals = this.formValues;
			let minPressing = 300;
			
			//if (vals.vinylColour !== 'black' || vals.jacketType !== null || vals.artwork.label !== null) {
			if (vals.vinylColour !== 'black' || vals.jacketType !== null || vals.labelType !== 'white') { // *** ISSUE jacket type white.
				minPressing = 500;
			}
			
			return (vals.pressing < minPressing) ? minPressing : vals.pressing;
		},
		calcVinylCost() {
			const costs = this.dbCosts;
			const vals = this.formValues;
			const opts = this.formOptions;
			
			// ensure pressing is equal to minimum order
			const pressing = this.minPressing;
			
			let vinylCost = 0; // total cost for vinyl
			let vinylUnitCost = 0; // individual vinyl cost
			
			// get unit price
console.clear();
console.log('-------------------','VINYL COST','-------------------');				
console.log('vinylCost = 0;', '{ value:', vinylCost, '}');				
console.log('vinylUnitCost = 0;', '{ value:', vinylUnitCost, '}');
			vinylUnitCost = this.quantityDiscount(costs.vinyl140);
			
			Object.keys(costs.vinyl140).reverse().every(key => {
				if (pressing >= Number(key)) {
//					vinylUnitCost = costs.vinyl140[key];
console.log('vinylUnitCost = costs.vinyl140['+key+'];', '{ value:', costs.vinyl140[key], 'subtotal:', costs.vinyl140[key], '}');				
					return false; // break loop
				}
				return true;
			});
			
			// add vinyl weight unit surcharge
			if (vals.vinylGrade === 160) {
				vinylUnitCost += costs.vinyl160;
console.log('vinylUnitCost += costs.vinyl160;', '{ value:', costs.vinyl160, 'subtotal:', vinylUnitCost, '}');				
				
			} else if (vals.vinylGrade === 180) {
				vinylUnitCost += costs.vinyl180;
console.log('vinylUnitCost += costs.vinyl180;', '{ value:', costs.vinyl180, 'subtotal:', vinylUnitCost, '}');				
			}
			
			// add coloured vinyl costs
			if (vals.vinylColour !== 'black') {
				vinylCost += costs.colour.cleaning;
console.log('vinylCost += costs.colour.cleaning;', '{ value:', costs.colour.cleaning, 'subtotal:', vinylCost, '}');				
				// find selected colour option
				const colour = opts.vinylColour.find(c => c.value === vals.vinylColour);
				
				if (colour.type === 'standard' || colour.type === 'limited') {
					vinylUnitCost += costs.colour[colour.type];
console.log('vinylUnitCost += costs.colour['+colour.type+'];', '{ value:', costs.colour[colour.type], 'subtotal:', vinylUnitCost, '}');				
					
				} else if (colour.type === 'splatter' || colour.type === 'marble') {
					vinylUnitCost += costs.colour['standard'];
console.log('vinylUnitCost += costs.colour[standard];', '{ value:', costs.colour['standard'], 'subtotal:', vinylUnitCost, '}');				
					vinylUnitCost += costs.colour[colour.type]; // effect surcharge					
console.log('vinylUnitCost += costs.colour['+colour.type+'];', '{ value:', costs.colour[colour.type], 'subtotal:', vinylUnitCost, '}');				
				}
			}
			
			vinylCost += (pressing * vals.vinylCount) * vinylUnitCost;
console.log('vinylCost += (pressing * vals.vinylCount) * vinylUnitCost;', '{ value:', (pressing * vals.vinylCount) * vinylUnitCost, 'subtotal:', vinylCost, '}');				
			
			// add cutting/processing surcharges (CHANGED)
			vinylCost += vals.vinylCount * costs.processing; // per vinyl
			vinylCost += vals.vinylCount * costs.cutting; // per vinyl
			
console.log('vinylCost += (vals.vinylCount * (costs.cutting + costs.processing);', '{ value:', vals.vinylCount * (costs.cutting + costs.processing), 'subtotal:', vinylCost, '}');				
			
			// add fixed delivery cost
			vinylCost += costs.delivery;
console.log('vinylCost += costs.delivery;', '{ value:', costs.delivery, 'subtotal:', vinylCost, '}');				
			
console.log('return vinylCost;', '{ total:', vinylCost, '}');				
			return vinylCost;
//			return this.currencyFormat(vinylCost);
		},
		calcPackingCost() { // CHANGED (new)
			const costs = this.dbCosts;
			const vals = this.formValues;
			const pressing = this.minPressing;
console.log('-------------------','PACKING COST','-------------------');				
			const packingCost = (pressing * vals.vinylCount) * costs.packing; // per vinyl
console.log('packingCost = (pressing * vals.vinylCount) * costs.packing;', '{ value:', costs.packing, 'subtotal:', packingCost ,'}');			
			return packingCost;
		},
		calcLabelCost() {
console.log('-------------------','LABEL COST','-------------------');				
			const costs = this.dbCosts;
			const vals = this.formValues;
			
			let labelCount = (this.minPressing * vals.vinylCount);
console.log('labelCount = (pressing * vals.vinylCount);', '{ value:', labelCount, '}');				
			
			if (labelCount < costs.label[vals.labelType].minQty) {
				labelCount = costs.label[vals.labelType].minQty;
console.log('labelCount = (labelCount < costs.label['+vals.labelType+'].minQty);', '{ value:', labelCount, '}');				
			}
			
			let labelCost = 0;
console.log('labelCost = 0;', '{ value:', labelCost, '}');				
			
			// only apply costs
			labelCost += labelCount * costs.label[vals.labelType][1];
console.log('labelCount += labelCount * costs.label[vals.labelType][1];', '{ value:', costs.label[vals.labelType][1], 'subtotal:', labelCost, '}');				
			
			// add label origination fixed surcharge 
			labelCost += costs.label[vals.labelType].origination;
console.log('labelCount += costs.label[vals.labelType].origination;', '{ value:', costs.label[vals.labelType].origination, 'subtotal:', labelCost, '}');
			
console.log('return labelCost;', '{ total:', labelCost, '}');				
			return labelCost;
		},
		calcInnerCost() {
console.log('-------------------','INNER COST','-------------------');				
			const costs = this.dbCosts;
			const vals = this.formValues;
			
			// ensure pressing is equal to minimum order
			const pressing = this.minPressing;
			
			let innerUnitCost = 0;
			
			// get inner type unit cost
			const keys = vals.innerType.split(' '); // type, colour
			
			if (keys[0] === 'card' && keys[1] === 'printed') {
				innerUnitCost = this.quantityDiscount(costs.packaging.card.printed);
				
				Object.keys(costs.packaging.card.printed).reverse().every(key => {
					if (pressing >= Number(key)) {
//						innerUnitCost = costs.packaging.card.printed[key];
console.log('innerUnitCost = costs.packaging.card.printed['+key+'];', '{ value:', costs.packaging.card.printed[key], 'subtotal:', costs.packaging.card.printed[key], '}');
						return false; // break loop
					}
					return true;
				});
				
				// surcharge for holes
				if (vals.innerHole !== null) {
					innerUnitCost += costs.packaging.card.hole;
console.log('innerUnitCost += costs.packaging.card.hole;', '{ value:', costs.packaging.card.hole, 'subtotal:', innerUnitCost, '}');
				}
				
			} else { // standard black or white bag or card with holes (no hole surcharge)
				innerUnitCost = costs.packaging[keys[0]][keys[1]];
console.log('innerUnitCost = costs.packaging['+keys[0]+']['+keys[1]+'];', '{ value:', costs.packaging[keys[0]][keys[1]], 'subtotal:', innerUnitCost, '}');
			}
			
console.log('return = (pressing * vals.vinylCount) * innerUnitCost;', '{ value:', (pressing * vals.vinylCount) * innerUnitCost, '}');
			return (pressing * vals.vinylCount) * innerUnitCost;			
		},
		calcJacketCost() {
console.log('-------------------','JACKET COST','-------------------');				
			const costs = this.dbCosts;
			const vals = this.formValues;
			
			// ensure pressing is equal to minimum order
			const pressing = this.minPressing;
			
			// get jacket unit cost
			let jacketUnitCost = 0;
			
console.log('jacketType = vals.jacketType;', '{ value:', vals.jacketType, '}');
console.log('jacketUnitCost = 0;', '{ value:', jacketUnitCost, '}');
			
			if (vals.jacketType === 'gatefold') {
				jacketUnitCost = this.quantityDiscount(costs.packaging.jacketGatefold);
				
				Object.keys(costs.packaging.jacketGatefold).reverse().every(key => {
					if (pressing >= Number(key)) {
//						jacketUnitCost = costs.packaging.jacketGatefold[key];
console.log('jacketUnitCost = costs.packaging.jacketGatefold['+key+'];', '{ value:', costs.packaging.jacketGatefold[key], 'subtotal:', costs.packaging.jacketGatefold[key], '}');
						return false; // break loop
					}
					return true;
				});				
				
			} else if (vals.jacketType !== null) {
				const jacketKey = (vals.vinylCount > 1) ? 'jacket5mm' : 'jacket3mm';
				jacketUnitCost = this.quantityDiscount(costs.packaging[jacketKey]);
				
				Object.keys(costs.packaging[jacketKey]).reverse().every(key => {
					if (pressing >= Number(key)) {
//						jacketUnitCost = costs.packaging[jacketKey][key];
console.log('jacketUnitCost = costs.packaging['+jacketKey+']['+key+'];', '{ value:', costs.packaging[jacketKey][key], 'subtotal:', costs.packaging[jacketKey][key], '}');
						return false; // break loop
					}					
					return true;
				});	
				
				// surcharge for holes
				if (vals.innerHole !== null) {
					jacketUnitCost += costs.packaging.card.hole;
					console.log('jacketUnitCost += costs.packaging.card.hole;', '{ value:', costs.packaging.card.hole, 'subtotal:', jacketUnitCost, '}');
				}
			}
			
			let jacketCost = pressing * jacketUnitCost;
console.log('jacketCost = (pressing * jacketUnitCost);', '{ value:', (pressing * jacketUnitCost), '}');
			
console.log('return jacketCost;', '{ total:', jacketCost, '}');				
			return jacketCost;
		},
		calcShrinkCost() {
console.log('-------------------','SHRINK WRAP COST','-------------------');				
			const costs = this.dbCosts;
			const vals = this.formValues;
			
			const shrinkUnitCost = (vals.shrink === true) ? costs.packaging.shrink : 0;
console.log('shrinkUnitCost = (vals.shrink === true) ? costs.packaging.shrink : 0;', '{ value:', shrinkUnitCost, '}');
console.log('return this.minPressing * shrinkUnitCost;', '{value:', this.minPressing * shrinkUnitCost, '}');
			return this.minPressing * shrinkUnitCost;
		},
		calcTestStockCost() {
console.log('-------------------','TEST STOCK COST','-------------------');
			const costs = this.dbCosts;
			const vals = this.formValues;
			
			let testStockDelivery = 0;
			
			if (vals.testStockQty) {
				testStockDelivery = (costs.testPressing.production + costs.testPressing.delivery);
			}
console.log('return (costs.testPressing.production + costs.testPressing.delivery)', '{ value:', testStockDelivery, '}');
			return testStockDelivery;
		},
		calcSubtotal() {
console.log('-------------------','SUBTOTAL','-------------------');				
console.log('return (this.calcVinylCost + this.calcPackingCost + this.calcLabelCost + this.calcInnerCost + this.calcJacketCost + this.calcShrinkCost + this.calcTestStockCost);', '{ total:', (this.calcVinylCost + this.calcPackingCost + this.calcLabelCost + this.calcInnerCost + this.calcJacketCost + this.calcShrinkCost + this.calcTestStockCost), '}');
			return (this.calcVinylCost + this.calcPackingCost + this.calcLabelCost + this.calcInnerCost + this.calcJacketCost + this.calcShrinkCost + this.calcTestStockCost);
		},
		calcUserStockValue() {
			const vals = this.formValues;
			
			const userStockValue = (vals.userStockQty * vals.salePrice);
			
			return userStockValue;
		},
		calcSalesStockValue() {
			const vals = this.formValues;			
			const pressing = this.minPressing;
			
			return (vals.salePrice * (pressing - vals.userStockQty));
		},
		calcTrrProjectFee() {
			const costs = this.dbCosts;
			
			let trrProjectFee = ((this.calcSalesStockValue + this.calcUserStockValue) / 100) * costs.trrFeePct;
			
			if (trrProjectFee < costs.trrFeeMin) {
				trrProjectFee = costs.trrFeeMin;	
			}
			
			return trrProjectFee;
		},
		calcTotal() {
console.log('-------------------','TOTAL','-------------------');				
//console.log('return (this.calcSubtotal + this.calcTrrProjectFee);', '{ total:', (this.calcSubtotal + this.calcTrrProjectFee), '}');
//			return (this.calcSubtotal + this.calcTrrProjectFee);
console.log('return (this.calcSubtotal);', '{ total:', (this.calcSubtotal), '}');
			return (this.calcSubtotal);
		},
		
		calcUserProfit() {
			const userProfit = (this.calcSalesStockValue - (this.calcTotal + this.calcTrrProjectFee));
			
			return userProfit;
		},
		
		
		calcProductionThreshold() {
//console.log('-------------------','PRODUCTION THRESHOLD','-------------------');				
//console.log('return (this.calcTotal / this.formValues.salePrice);', '{ total:', (this.calcTotal / this.formValues.salePrice), '}');
			return Math.ceil(this.calcTotal / this.formValues.salePrice);
		},
		calcProductionFee() {
//console.log('-------------------','PRODUCTION FEE','-------------------');				
			return (this.calcProductionThreshold);
		},
		calcRevenue() {
//console.log('-------------------','REVENUE','-------------------');				
//console.log('return (this.calcTotal * this.calcProductionFee);', '{ total:', (this.calcTotal * this.calcProductionFee), '}');
			return (this.calcTotal * this.calcProductionFee);
		},
		calcRevenueThreshold() {
//console.log('-------------------','REVENUE THRESHOLD','-------------------');				
//console.log('return (this.calcRevenue / this.formValues.salePrice);', '{ total:', (this.calcRevenue / this.formValues.salePrice), '}');
			return (this.calcRevenue / this.formValues.salePrice);
		},
		
		
		
		//------------------------------------------
		user() {
			return this.$store.state.auth.user;
		},
		currentSchema() {
			return this.schemas[this.step - 1];
		},
		validStep() {
//			console.log('step1:',this.$refs.step1.errors);
			return true;
		},
		projectTotal() {
			return 0;
		},
		minsPerSide() {
			var rpm = this.formValues.vinylSpeed;	
			var format = this.formValues.vinylFormat;
			var min;
			
			if (format === 12) {
				min = (rpm === 45) ? 7.5 : 21;
			} else if (format === 10) {
				min = (rpm === 45) ? 7.5 : 21;
			} else if (format === 7) {
				min = (rpm === 45) ? 5 : 7.5;
			}
			
			return min;
		},
		inner() {
			//
			return this.formValues.innerType;
		},
		selectedColour() {
			return this.formOptions.vinylColour.find(c => c.value === this.formValues.vinylColour);
		},
		recordStyle() {
			const option = this.selectedColour;
			return option.style + ':' + option.colour + ';';
		},
		imgStyle() {
			const option = this.selectedColour;
			return (option.blend !== null) ? 'mix-blend-mode:' + option.blend + ';' : '';
		},
		recordColour() {
			const option = this.selectedColour;
			return option.option;
		},
	},
	methods: {
		disableHole(innerHole) {
			const res = (innerHole.value !== 'both' && this.formValues.innerType !== 'card printed') ? true : false;
			console.log('disableHole:', innerHole.value, this.formValues.innerType, res);
			return res;
		},
		disableJacket(jacketType) {
			// prevent none option for jacket if inner is bag
			return (jacketType.value === null && (this.formValues.innerType==='bag white' || this.formValues.innerType === 'bag black')) ? true : false;
		},				
		innerChange(innerType) {
			if (innerType.value === 'card printed') {
				this.formValues.innerHole = null;
				
			} else {
				// enforce both holes
				this.formValues.innerHole = 'both';
				
				if (innerType.value === 'bag white' || innerType.value === 'bag black') {
					// enforce jacket (default to white)
					this.formValues.jacketType = 'white';
				}
			}
		},
		currencyFormat(amount) {
			const formatter = new Intl.NumberFormat('en-GB', {
				style: 'currency',
				currency: 'GBP',
			});
			
			return formatter.format(amount);
		},
		quantityDiscount(qtyObj) {
			let cost = null;
			
			// retrieve price based on quantity
			Object.keys(qtyObj).reverse().every(key => {
				if (this.minPressing >= Number(key)) {
					cost = qtyObj[key];
					return false; // break loop
				}
				
				return true; // continue (needed)
			});
			
			return cost;
		},		
		flip() {
			if (this.flippingArtwork !== null) {
				return;
				
			} else if (this.revealInner && this.flipArtwork) {
				setTimeout(() => this.flip(), 300);
				this.revealInner = false;
				return;
			}
			
			this.flipArtwork = !this.flipArtwork;
			
			this.flippingArtwork = this.flipArtwork;
			setTimeout(() => this.flippingArtwork = null, 600);
			
			if (!this.flipArtwork) {
				setTimeout(() => this.flipArtwork = null, 600);
			}
			
			this.revealRecord = false;
			this.revealInner = false;
		},
		showRecord() {
			this.revealRecord = !this.revealRecord;
		},
		showInner() {
			this.revealInner = !this.revealInner;
		},
		prevStep() {
			if (this.step === 1) {
				this.$router.push({ name: 'Projects' });
			} else {
				this.step --;
			}
		},
		nextStep(formValues) {
console.log('formValues:',formValues);
			
			if (this.step === 5) {
console.log('DONE');
				return;
			}
			
			this.step ++;
		},
		async doStep1(formData, actions) {
			try {
				if (formData !== actions) {
					
					
					
					return true;	
				}
				
			} catch (err) {
				console.log('processStep1:error:', err);
			}			
		},
		async doStep2(formData, actions) {
			try {
				if (formData !== actions) {
					return true;	
				}
				
			} catch (err) {
				console.log('processStep2:error:', err);
			}			
		},
		async doStep3(formData, actions) {
			try {
				if (formData !== actions) {
					return true;	
				}
				
			} catch (err) {
				console.log('processStep3:error:', err);
			}			
		},
		async doStep4(formData, actions) {
			try {
				if (formData !== actions) {
					return true;	
				}
				
			} catch (err) {
				console.log('processStep4:error:', err);
			}			
		},
		async doStep5(formData, actions) {
			try {
				if (formData !== actions) {
					return true;	
				}
				
			} catch (err) {
				console.log('processStep5:error:', err);
			}			
		},
	},
};
</script>

<style lang="scss">
#builder {
	display: flex;
	.visual {
		hgroup {
			margin:55px 0 40px 0;
			h1 {
			}
			h2 {}
		}
		flex-grow: 1;
		min-width: 280px;
		max-width: 800px;
		min-height: 100px;
		>div { // wrapper
//			position: relative;
			position: sticky;
			top: 170px;
		}
		.artwork {
			z-index: 0;
			position: relative;
			width: 50%;
			margin: 0;
			
//			&.flip-to-front .front,
			&.flipped:not(.flip-to-back) .front,
			&:not(.flipped):not(.flip-to-front) .back {
				visibility: hidden;
			}
			
			picture {
				box-sizing: content-box;
transition: .3s .3s ease-in-out;
				&.white {
					background: #FFF;					
				}
				&.black {
					background: #111;
				}
				&.printed {
					background: #CCC;
					img {
						opacity: 1;
					}
				}
				&:before,
				&:after {
//					width: 105%;
					transition: all .3s ease-in-out;
				}
				img {
					opacity: 0;
					transition: opacity .3s ease-in-out;
				}
			}
			.album {
padding-top: 100%;
				&:before { // inside back colour
					content: '';
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
//					background: #222;
//					transition: .3s ease-in-out;
				}
				picture {
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					&:before {
						position: absolute;
						top: 0;
//						left: 0;
						width: 100%;
						height: 100%;
						padding-top: 0;
					}
					&:after { // album shadow
						padding-top: 70%;
					}
				}
				&.front picture:before {
					left: 0;
				}
				&.back picture {
					transition-delay: 0s;
					&:before {
//						right: 0;
						transition-delay: 0s;
					}
				}
				&.back picture,
				&.back picture:before,
				&.back picture:after {
					left: auto;
					right: 0;
				}
				svg.inner {
					position: absolute;
					top: 0;
					left: 0;
					height: 100%;					
					width: 100%;
					image { // svg image
						opacity: 0;
						transition: .3s ease-in-out;
					}
					rect { // bag / non-printed card
						height: 100%;					
						width: 100%;
						fill: #111;
						transition: .3s ease-in-out;
					}
				}
//				&.printed:not(.hole-front):not(.hole-both) {
				&.printed {
					
					&.front.hole-front {
//						&.black {
							&:before { // solid inside back colour
								background: #222;
							}
//						}
						&.white {
							&:before { // inside back colour
								background: #DDD;
							}
//							svg.inner {
//								rect {
//									fill: #FFFFFF;
//								}
//							}
						}
					}
					&.front:not(.hole-front):not(.hole-both) {
						.record {
//							transform: translateX(35%) rotate(90deg);
						}
					}
					&.front.hole-back,
					&:not([class*="hole-"]) {
						#front-inner-mask {
							display: none;
						}
					}
					&.back.hole-front,
					&:not([class*="hole-"]) {
						#back-inner-mask {
							display: none;
						}
					}
					svg.inner {
						image {
							opacity: 1;							
						}
					}
				}
				&.black {
					&:before { // inside back colour
//						background: #222;
					}
				}
				&.white {
					&:before { // inside back colour
//						background: #DDD;
					}
					svg.inner {
						rect {
							fill: #FFFFFF;
						}
					}
				}
			}
			&.jacket {
				.front picture {
					box-shadow: 3px 0 2px 0px rgb(#000, .05);					
				}
				.back picture {
					box-shadow: -3px 0 2px 0px rgb(#000, .05);
				}
//				&:not(.flipped):not(.reflipped):not(.inner) {
//				&:not(.flipped):not(.reflipped):not(.inner) {
//				&.flip-to-front,
//				&.flip-to-back,
//				&:not(.flipped):not(.reflipped):not(.inner) {
				&:not(.inner) {
					.front {
						svg.inner,
						.record:not(.reveal),
						&:before {
							transform: translateX(5%);
						}
						picture {
							transition-delay: 0s;
							&:before { // lighting
								width: 105%;
							}							
						}
					}
				}
			}
			.front {
				&.black {
					picture.black {
						border-right: 1px solid rgba(#FFF, .1);
					}					
				}
				.record {
					div.label {
						background-image: linear-gradient(to bottom right, rgba(#000, .2), rgba(#FFF, .2));
						&:empty {
							background-color: #FFF;
						}
					}
				}
			}
			.back {
				&.black {
					picture.black {
						border-left: 1px solid rgba(#FFF, .1);
					}					
				}
			}
			&:not(.jacket) ~button.inner,
//			&.inner ~button.reveal,
			&:not(.flipped) ~button.inner.back,
//			&.flipped:not() ~button.inner.back,
			&.flipped ~button.reveal,
			&.flipped ~button.inner.front {
				opacity: 0;
				pointer-events: none;
				transform: scale(0);
				transition-delay: 0s;
			}			
			&.flipped,
			&.reflipped {
				picture {
					&:before {
//						width: 100% !important; // close inner
					}
				}
				.front svg.inner {
					transform: translateX(0);
				}
			}
			&.flipped {
				animation-delay: .3s;				
				.album {
					transition-delay: .3s;
				}			
//				.record {
				.back .record { // MAINTAIN INNER
					transform: translateX(0);					
				}
			}
			&.reflipped {
				.record {
					transition-delay: .6s;
				}			
			}
			&.inner {
				&:not(.flipped):not(.reflipped) {
					.front {
						//&:before {
						svg.inner {
							transform: translateX(90%);
						}
						.record {
							transform: translateX(90%) rotate(150deg);
						}
						picture {
							&:before,
							&:after {
								width: 190%;
							}							
						}
					}
				}
			}
			&.flipped {
				.back {
					picture {
//						transition-delay: .6s;
//						transform: translateX(5%);
					}
				}
				&.jacket:not(.flip-to-back) {
					.back {
						picture {
							margin-right: -5%;
//							transition-delay: .3s;
							&:before,
							&:after {
								width: 105%;
//								transition-delay: .3s;
							}							
						}
					}
				}
				&.jacket.inner {
					.back {
						picture {
//							transform: translateX(90%);
							margin-right: -90%;
							&:before,
							&:after {
								width: 190%;
							}							
						}
					}
				}
			}
			.record {
				z-index: 0;
			}
		}
		.record {
//			z-index: -1;
			position: absolute;
			top: 0;
			left: 0;
			display: flex;
			align-items: center;
			justify-content: center;
			width: 100%;
			height: 100%;
//			transform: translateX(35%) rotate(90deg);
			backface-visibility: hidden;
			background: #323232 url(/img/vinyl.png) no-repeat center;
			background-size: 100% 100%;
			border-radius: 50%;
			transition: .3s ease-in-out;
			overflow: hidden;
			img {
				position: absolute;
				top: 50%;
				left: 50%;
				width: calc(100% + 4px);
				height: calc(100% + 4px);
				transform: translate(-50%,-50%);
			}
			&.reveal {
				transform: translateX(70%) rotate(135deg);
			}
			.label {
				position: relative;
				display: flex;
				align-items: center;
				justify-content: center;
				width: 33%;
				height: 33%;
				background: #FFF;
				border-radius: 50%;
				overflow: hidden;
				img {
					opacity: 0;
					transition: opacity .3s ease-in-out;
				}
				&.printed {
					img {
						opacity: 1;
					}
				}
				&:before { // outer center ring
					z-index: 1;
					content: '';
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%,-50%);
					width: 33%;
					height: 33%;
					border-radius: 50%;
					border: 2px solid rgba(#000, .05);
				}
				&:after { // center hole
					z-index: 2;
					content: '';
					width: 9%;
					height: 9%;
					border-radius: 50%;
					box-shadow: inset 1px -1px 6px rgba(#000, .4);
					background: #999;
				}
			}
		}		
		.records {
			position: relative;
			width: 50%;
			padding-top: 15%;
			margin-top: 2%;
			.record {
				pointer-events: none;
				position: absolute;
				top: 0;
				left: 0;
				box-shadow: -2px -2px 10px 0 rgba(#000, .3);
				opacity: 0;
				transform-origin: 50% 25%;
				transition: all .3s ease-in-out, opacity .3s ease-in-out, transform .3s ease-in-out; // for seperate delays
				&:nth-child(1) {
					z-index: 3;
//					margin-left: 13%;
					margin: 10% 0 3% 0;
					transition-delay: 0s, 0s, 0s;
				}
				&:nth-child(2) {
					z-index: 2;
					margin: -5px 0 0 32%;
					transition-delay: 0s, .2s, .2s;
				}
				&:nth-child(3) {
					z-index: 1;
					margin: -5px 0 0 71%;
					transition-delay: 0s, .4s, .4s;
				}
				&.on {
					opacity: 1;
					&:nth-child(1) {
						transition-delay: 0s, .4s, .4s;
					}
					&:nth-child(2) {
						transition-delay: 0s, .2s, .2s;
					}
					&:nth-child(3) {
						transition-delay: 0s, 0s, 0s;
					}
				}
				&:after { // label shadow
					content: '';
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%,-50%);
					width: 33%;
					height: 33%;
					border-radius: 50%;
					background-image: linear-gradient(to bottom right, rgba(0, 0, 0, 0.2), rgba(255, 255, 255, 0.2))
				}
			}
		}
		&.size-12 {
			.artwork >div {
				transform: scale(1);
			}
			.records {
				.record {
					transform: translateY(-500px) rotate(180deg) scale(1);
					&.on {
						transform: translateY(0) rotate(180deg) scale(1);
					}						
				}
			}
		}
		&.size-10 {
			.artwork >div {
				transform: scale(.95);
			}
			.records {
				.record {
					transform: translateY(-500px) rotate(180deg) scale(.95);
					&.on {
						transform: translateY(0) rotate(180deg) scale(.95);
					}
				}
			}
		}
		&.size-7 {
			.artwork >div {
				transform: scale(.9);
			}
			.records {
				.record {
					transform: translateY(-500px) rotate(180deg) scale(.9);
					&.on {
						transform: translateY(0) rotate(180deg) scale(.9);
					}
				}
			}
		}
		button {
			position: absolute;
			width: 30px;
			height: 30px;
			transition-delay: 1.2s;
			&.flip {
				top: 6%;
				left: 4%;
			}
			&.reveal {
				top: 34.5%;
//				left: 58%;
				left: 48%;
			}
			&.inner.front {
				top: 50%;
//				left: 48%;
				left: 51%;
			}
			&.inner.back {
				top: 50%;
				left: -2%;
			}
		}
	}
	.options {
		z-index: 4;
		position: relative;
		display: flex;
		flex-direction: column;
		transition: transform 0.3s ease-in-out;
		width: 100%;
		min-width: 280px;
		max-width: 380px;
		min-height: 140px;
		box-shadow: 0 2px 50px 0px rgba(#000, .2);
		background: #FFF;
position: sticky;
top: 160px;
min-height: 360px; // 560px screen height
max-height: calc(100vh - 200px);		
		.steps { //
			display: flex;
			height: 60px;
			min-height: 60px;
			align-items: center;
			padding: 0 10px;
			background: #56B9A0;
			overflow: hidden;
			li {
				position: relative;
				padding: 0 10px;
				list-style-type: none;
				font-size: 1.2rem;
				font-weight: 600;
				color: rgba(#FFF, .5);
				text-transform: uppercase;
				transition: color 0.3s ease-in-out;				
				&:before {
					content: '';
					width: 20px;
					height: 20px;
					position: absolute;
					top: 100%;
					left: 50%;
					transform: translate(-50%, 25px) rotate(45deg);
					background: #FFF;
					transition: transform 0.3s ease-in-out;					
				}
				&.done,
				&.active {
					color: #FFF;
				}
				&.active:before {
					transform: translate(-50%, 15px) rotate(45deg);
				}
			}
		}
		> div {
			overflow-x: hidden;
			overflow-y: scroll;
			height: 100%;
			margin-bottom: 100px;
			padding: 20px;
		}
		.ctrl {
			position: absolute;
			bottom: 20px;
			left: 20px;
			right: 20px;
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin-top: 20px;
			&:before {
				content: '';
				background: #FFF;
				position: absolute;
				top: -20px;
				right: -20px;
				bottom: -20px;
				left: -20px;
				box-shadow: 0 2px 50px rgba(#FFF, .2);				
			}
			.back {
				margin-right: 20px;
			}
			.but-price {
				flex-grow: 1;
				button {
					margin-left: auto;
				}
				.price {
					display: flex;
					flex-wrap: wrap;
					align-items: baseline;
					small:first-of-type {
						flex-grow: 1;
					}
					strong {
						width: 100%;
					}
				}
			}
		}
	}
}
fieldset {
	&.colour,
	&.range {
		position: relative;
		&:not(.fw) {
			padding-right: 80px;			
		}
		legend {
			float: left; // inside fieldset
			width: 100%;
		}
		>div {
			position: absolute;
			top: 50%;
			right: 0;
			width: 60px;
			height: 60px;
			transform: translateY(-50%);
			&.vinyl-count,
			&.vinyl-colour {
				background: url(/img/vinyl.png) no-repeat center;
				background-size: 100% 100%;
				border-radius: 50%;
				img {
					width: 100%;
				}
				span {
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%,-50%);
					display: flex;
					align-items: center;
					justify-content: center;
					width: 33%;
					height: 33%;
					font-weight: 600;
					font-size: 1.2rem;				
					font-family: 'SansBold';
					background: #FFF;
					border-radius: 50%;
				}
			}
			&.vinyl-count {
				background-color: #323232;
				img {
					mix-blend-mode: plus-lighter;
				}
			}
			&.vinyl-colour {
				display: flex;
				flex-direction: column;
				align-items: center;
				transition: .3s ease-in-out;
				span {
					&:after {
						content: '';
						width: 12%;
						height: 12%;
						background: #999;
					}					
				}
				small {
					margin-top: 5px;
					line-height: 1;
					text-align: center;
					font-size: 1.2rem;
				}
				img + img { // label image
					position: absolute;
					top: 50%;
					border-radius: 50%;
					left: 50%;
					transform: translate(-50%,-50%);
					width: 33.33%;
					height: 33.33%;
					transition: opacity .3s ease-in-out;
					+ span { // label center
						transition: background .3s ease-in-out;
					}
				}
				&.white {
					img + img {
						opacity: 0;
					}
				}
				&.printed {
					img + img { // label image
						opacity: 1;
						+ span { // label center
							background: transparent;
						}
					}
				}
			}
			&.vinyl-grade {
				display: flex;
				align-items: center;
				justify-content: center;
				font-family: 'SansBold';
				font-size: 1.6rem;
				font-weight: 600;
				padding-top: 15px;
				background: url(/img/range-vinyl-grade.svg) no-repeat center;
				background-size: contain;
			}
			&.vinyl-format,
			&.vinyl-speed {
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				line-height: 1;
				big {
					font-size: 3.4rem;
					font-family: 'SansBold';
				}
			}
			&.vinyl-format {
				small {
					font-size: 1.2rem;
					text-align: center;
				}
			}
			&.vinyl-speed {
				small {
					margin-top: -2px;
					font-size: 2.2rem;
					font-family: 'SansBold';
				}
			}
			&.inner,
			&.jacket {
				&:after { // lighting
					content: '';
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					background-image: linear-gradient(to bottom right, rgba(#000, .1), rgba(#FFF, .2));
					box-shadow: 0 2px 4px 0px rgb(#000, .15);
					transition: .3s ease-in-out;
				}				
			}
			&.inner {
				transition: .3s ease-in-out;
				&:before { // hole
					z-index: 1;
					content: '';
					display: block;
					position: absolute;
					top: 50%;
					left: 50%;
					width: 38%;
					height: 38%;
					transform: translate(-50%,-50%);
					border-radius: 50%;
					background: #FFF;
					transition: .3s ease-in-out;
					
				}
				img {
					width: 100%;
					height: 100%;
					object-fit: cover;
					opacity: 0;
					transition: .3s ease-in-out;
				}
				&.black {
					background: #111;
				}
				&.white {
					background: #FFF;
				}
				&.printed {
					img {
						opacity: 1;
					}
					&:not(.front):not(.both):before {
						opacity: 0;
					}
				}
			}
			&.jacket {
				transition: .3s ease-in-out;
				img {
					width: 100%;
					height: 100%;
					object-fit: cover;
					opacity: 0;
					transition: .3s ease-in-out;
				}
				&.black {
					background: #111;
				}
				&.white {
					background: #FFF;
				}
				&.printed {
					img {
						opacity: 1;
					}
				}
			}
			&[class="jacket"] {
				&:after { // lighting
					opacity: 0;
				}
			}
		}
	}
	&.colour {
		justify-content: flex-start;
		label {
			width: auto;
			margin-top: 10px;
			&:not(:last-of-type) {
				margin-right: 10px;				
			}
			span {
				width: 30px;
				height: 30px;
				text-indent: 30px;
				white-space: nowrap;
				overflow: hidden;
				border-radius: 50%;	
			}
			input {
				z-index: 1;
				cursor: pointer;
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				border-radius: 50%;
				border: 4px solid #FFF;
				box-shadow: 0 2px 10px rgba(#000, .2);
				transition: .3s ease-in-out;
				&:hover {
					border-width: 6px;
				}
			}
		}
	}
	&.range {
		&.field {
			margin-top: 20px;
			margin-bottom: 20px;
			padding: 8px;
			border: 1px solid $grey;
			border-radius: 4px;
			legend {
				margin-top: -5px;
				padding-left: 2px;
				font-size: 1rem;
				color: $grey;
			}
			label {
				margin-bottom: 0;
				margin-top: 0;
			}
		}
		
		
		&.disabled label, // all disabled
		label.disabled { // individual disabled
			cursor: not-allowed;
			span {
				color: #D8D8D8 !important;					
			}			
		}
		label {
			cursor: pointer;
			display: inline-flex;
			width: auto;
			min-width: 20px;
			margin: 10px 0 20px 0;
			span {
				position: static;
				width: 100%;
				padding-top: 20px;
//				font-family: 'SansBold';
				font-size: 1.2rem;
				line-height: 1.2;
				&:before,
				&:after {
					content: '';
					position: absolute;
					top: 5px;
					width: 50%;
					height: 10px;
					background: #323232;
					transition: .3s ease-in-out;
				}
				&:before {
					left: 0;
				}
				&:after {
					right: 0;
				}
			}			
			&:hover {
				span {
					color: #56B9A0;					
				}
			}
			&:first-of-type {
				span {
					text-align: left;
					padding-left: 6px;
					&:before {
						display: none;
					}
					&:after {
						width: 100%;
						border-radius: 5px 0 0 5px;
					}
				}
				input {
					left: 10px;					
				}
			}
			&:last-of-type {
				span {
					text-align: right;
					padding-right: 6px;
					&:before {
						width: 100%;
						border-radius: 0 5px 5px 0;
					}
					&:after {
						display: none;
					}
				}
				input {
					left: calc( 100% - 10px );					
				}
			}
			&:not(:first-of-type):not(:last-of-type) {
				span {
					text-align: center;
					&.wrap {
						padding-left: 15px;
						padding-right: 15px;
					}
				}
			}
			&.checked {
				span:after,
				~label span:before,
				~label span:after {
					background: #D8D8D8 !important;
				}
			}
		}
		input {
			z-index: 1;
			pointer-events: none !important;
			position: absolute;
			top: 0;
			left: 50%;
			margin-left: -10px;
			width: 20px;
			height: 20px;
			border-radius: 50%;
			border-color: #FFF;
			border-width: 2px;
			background: #323232;
			transition: .3s ease-in-out;
			&:not(:checked) {
				opacity: 0;
//				+ span:before {
//					background: red;
//				}
			}
		}
		&[data-stops="2"] {
			label {
				width: 50%;
			}
		}
		&[data-stops="3"] {
			label {
				width: 50%;
				&:nth-of-type(1),
				&:nth-of-type(3) {
					width: 25%;
				}
			}
		}
		&[data-stops="4"] {
			label {
				width: 30%;
				&:nth-of-type(1),
				&:nth-of-type(4) {
					width: 20%;
				}
			}
		}
		&[data-stops="5"] {
			label {
				width: 20%;
				span {
//					text-align: left !important;
				}
			}
		}
		&:hover {
			input,
			label span:before,
			label span:after {
				background: #56B9A0;				
			}
		}
	}
}
#testbed {
	display: inline-flex;
	justify-content: flex-start;
	align-items: self-end;	margin-bottom: 30px;
	label {
		display: flex;
		flex-direction: column;
//		align-items: center;
		width: 120px;
		margin-bottom: 10px;
		margin-right: 10px;
		span {
			order: -1;
			white-space: nowrap;
			margin-bottom: 10px;
		}
	}
	input,
	select {
		width: 100%;
		height: 40px;
		padding: 0 20px;
		border: 0;
		margin: 0;
	}
//	display: none;
}
#debug {
	display: flex;
	font-size: 1.2rem;
	li {
		list-style-type: none;
		width: 0;
		flex-grow: 1;
		&:nth-child(2) {
			line-height: 1.5;
		}
	}
//	display: none;
}
</style>